import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { handelize } from "../utils/utils"
import { filterValidNameFields } from "../utils/data"

const ArtistsPage = ({ data }) => {
  const artists = filterValidNameFields(data.airtableArtists)
  return (
    <Layout>
      <SEO title="Events" />
      <div className="max-w-5xl mx-auto w-full">
        <div className="my-8 text-center">
          <h1 className="my-8">Artists</h1>
        </div>
        <div className="flex flex-wrap">
          {artists.map(({ node }) => {
            const { Name, Featured_Art } = node.data

            return (
              <Link
                to={`/${handelize(Name)}`}
                className="px-4 md:w-1/3 w-full block text-left mb-10 no-underline text-center flex flex-col justify-between"
                key={Name}
              >
                <div>
                  {Featured_Art && Featured_Art[0].data.Images && (
                    <Img
                      fluid={{
                        ...Featured_Art[0].data.Images.localFiles[0]
                          .childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      className="w-full flex-grow"
                    />
                  )}
                </div>
                <div className="pl-4 max-w-sm">
                  <h4 className="py-4">{Name}</h4>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ArtistsPage

export const query = graphql`
  {
    airtableArtists: allAirtable(
      filter: { table: { eq: "Artists" } }
      sort: { fields: data___Last_Name }
    ) {
      edges {
        node {
          data {
            Name
            Featured_Art {
              data {
                Name
                Images {
                  localFiles {
                    childImageSharp {
                      fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
